import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import Icon from '@haaretz/s-icon';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import type { IconName } from '@haaretz/l-icon-sprite/types';
import type { StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    display: 'flex',
    columnGap: space(4),
  },
  icon: {
    fontSize: space(6),
    ...merge(mq({ from: 'xl', value: { fontSize: space(7) } })),
  },
});

interface socialLinkType {
  IconName: IconName;
  href: string;
  a11yText: string;
}

const socialLinks: socialLinkType[] = [
  {
    IconName: 'rss',
    href: fork({
      default: 'https://www.haaretz.co.il/misc/rss',
      tm: 'https://www.themarker.com/misc/rss',
      hdc: 'https://www.haaretz.com/2022-05-29/ty-article/.premium/subscribe-to-rss-feed-and-other-ways-to-read-haaretz/00000181-0f20-d077-a1ff-ffe73cfd0000',
    }),
    a11yText: 'RSS',
  },
  {
    IconName: 'facebook',
    href: fork({
      default: 'https://www.facebook.com/haaretz',
      tm: 'https://www.facebook.com/TheMarkerOnline',
      hdc: 'https://www.facebook.com/haaretzcom',
    }),
    a11yText: fork({ default: 'הארץ בפייסבוק', tm: 'TheMarker בפייסבוק', hdc: 'Facebook' }),
  },
  {
    IconName: 'twitter',
    href: fork({
      default: 'https://twitter.com/haaretz',
      tm: 'https://twitter.com/themarker',
      hdc: 'https://twitter.com/haaretzcom',
    }),
    a11yText: fork({ default: 'הארץ ב-X ', tm: 'TheMarker ב-X', hdc: 'Twitter' }),
  },
  {
    IconName: 'instagram',
    href: fork({
      default: 'https://www.instagram.com/haaretz',
      tm: 'https://www.instagram.com/themarker_online',
      hdc: 'https://www.instagram.com/haaretzcom',
    }),
    a11yText: fork({ default: 'הארץ באינסטגרם', tm: 'TheMarker באינסטגרם', hdc: 'instagram' }),
  },
  {
    IconName: 'whatsapp',
    href: fork({
      default: 'https://whatsapp.com/channel/0029VaFVn385fM5Sx1C6Jr24 ',
      tm: 'https://whatsapp.com/channel/0029VaJPeVo8aKvBdkQZnA3N',
    }),
    a11yText: fork({
      default: 'הארץ בווטסאפ',
      tm: 'TheMarker בווטסאפ',
      hdc: 'Whatsapp',
    }),
  },
  {
    IconName: 'android',
    href: fork({
      default: 'https://play.google.com/store/apps/details?id=com.haaretz',
      tm: 'https://play.google.com/store/apps/details?id=com.themarker',
      hdc: 'https://play.google.com/store/apps/details?id=com.opentech.haaretz',
    }),
    a11yText: fork({
      default: 'אפליקציית הארץ באנדרואיד',
      tm: 'אפליקציית TheMarker באנדרואיד',
      hdc: 'Haaretz on Android',
    }),
  },
  {
    IconName: 'apple',
    href: fork({
      default: 'https://itunes.apple.com/us/app/id521559643',
      tm: 'https://apps.apple.com/il/app/themarker-%D7%93%D7%94%D7%9E%D7%A8%D7%A7%D7%A8/id360938308',
      hdc: 'https://apps.apple.com/us/app/haaretz-english-edition/id504537897',
    }),
    a11yText: fork({
      default: 'אפליקציית הארץ באייפון',
      tm: 'אפליקציית TheMarker באייפון',
      hdc: 'Haaretz on iOS',
    }),
  },
];

export interface SocialLinksProps {
  styleExtend?: StyleExtend;
}

export default function SocialLinks({ styleExtend = [] }: SocialLinksProps) {
  return (
    <ul className={s9(c.base, ...styleExtend)}>
      {socialLinks.map(link => {
        return (
          <li key={link.IconName}>
            <a href={link.href} target="_blank" rel="noreferrer" title={link.a11yText}>
              <Icon icon={link.IconName} styleExtend={[c.icon]} />
              <VisuallyHidden>{link.a11yText}</VisuallyHidden>
            </a>
          </li>
        );
      })}
    </ul>
  );
}

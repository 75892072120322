const twitterEmbedCssOverride =
  '[class^="tweet-actions_"] {' +
  'margin-right: 0 !important;' +
  '}' +
  '[class^="tweet-header_author__"] {' +
  'max-width: 50vw !important;' +
  '}' +
  '[class^="tweet-actions_actions__"] {' +
  'display: flex !important;' +
  'justify-content: space-around !important;' +
  '}';

export default twitterEmbedCssOverride;
